<template>
  <div>
    <users-list-filters
      :region-filter.sync="regionFilter"
      :region-options="regionOptions"
      :role-filter.sync="roleFilter"
      :role-options="dataFetchRoles"
      :audited-in-m-y-filter.sync="auditedInMYFilter"
      :audited-by-p-w-c-filter.sync="auditedByPWCFilter"
      :audited-options="answerQuestionOptions"
    />
    <user-list-add-new
      v-if="$can('ur_create')"
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="dataFetchRoles"
      :plan-options="planOptions"
      :region-options="regionOptions"
      @refetch-data="refetchData"
    />
    <user-assign-role
      v-if="$can('ur_update')"
      :is-assign-role-sidebar-active.sync="isAssignRoleSidebarActive"
      :role-options="dataFetchRoles"
      :user-data="currentDataUser"
      @refetch-data="refetchData"
    />

    <action-select
      v-if="$can('ur_update')"
      @reset-pass="onResetPassConfirm"
      @reset-all-pass="resetAllPass"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                v-if="$can('ur_create')"
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">Add User</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-overlay
        :show="showTable"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-table
          ref="refUserListTable"
          class="position-relative"
          sticky="100%"
          sticky-header="600px"
          :items="fetchUsers"
          responsive
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
        >
          <!-- Column: User -->
          <template #cell(user)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatar"
                  :text="avatarText(data.item.fullName)"
                  :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                  :to="{ name: 'user-view', params: { id: data.item.id } }"
                />
              </template>
              <b-link
                :to="{ name: 'user-view', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.firstName }} {{ data.item.lastName }}
              </b-link>
              <small class="text-muted"
                >{{ data.item.firstName }} {{ data.item.lastName }}</small
              >
            </b-media>
          </template>
          <!-- Column STT -->
          <template #cell(NO)="data">
            <b-form-checkbox
              v-model="userCheckbox"
              plain
              class="vs-checkbox-con"
              @change="PushToArray(userCheckbox, data.item.id)"
            >
              <span class="vs-checkbox">
                <span class="vs-checkbox--check">
                  <i class="vs-icon feather icon-check" />
                </span>
              </span>
            </b-form-checkbox>
          </template>
          <template #cell(createdAt)="data">
            <span>
              {{ formatDate(data.item.createdAt) }}
            </span>
          </template>
          <template #row-details="row">
            <b-card>
              <b-row class="mb-2">
                <b-col md="4" class="mb-1">
                  <strong>Full Name : </strong>{{ row.item.full_name }}
                </b-col>
                <b-col md="4" class="mb-1">
                  <strong>Post : </strong>{{ row.item.post }}
                </b-col>
                <b-col md="4" class="mb-1">
                  <strong>Email : </strong>{{ row.item.email }}
                </b-col>
                <b-col md="4" class="mb-1">
                  <strong>City : </strong>{{ row.item.city }}
                </b-col>
                <b-col md="4" class="mb-1">
                  <strong>Salary : </strong>{{ row.item.salary }}
                </b-col>
                <b-col md="4" class="mb-1">
                  <strong>Age : </strong>{{ row.item.age }}
                </b-col>
              </b-row>

              <b-button
                size="sm"
                variant="outline-secondary"
                @click="row.toggleDetails"
              >
                Hide Details
              </b-button>
            </b-card>
          </template>
          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(
                data.item.deletedAt ? false : true
              )}`"
              class="text-capitalize"
            >
              <!-- TODO change to is Active
            {{ data.item.status }} -->
              {{ data.item.deletedAt ? "Inactive" : "Active" }}
            </b-badge>
          </template>
          <!-- Column: Status -->
          <template #cell(productConfig)="data">
            <b-badge
              v-for="index in data.item.productConfig"
              :key="index"
              pill
              :variant="`light-${resolveUserAssetConfig(index)}`"
              class="text-capitalize"
            >
              {{ mapAssetConfig(index) }}
            </b-badge>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-link
              v-if="$can('ur_read')"
              :to="{ name: 'user-view', params: { id: data.item.id } }"
            >
              Preview
            </b-link>

            <b-link
              v-if="$can('ur_update')"
              :to="{ name: 'user-edit', params: { id: data.item.id } }"
            >
              Edit
            </b-link>

            <b-link v-if="$can('ur_delete')" @click="deleteUser(data.item.id)">
              Delete
            </b-link>

            <b-link v-if="$can('ur_update')" @click="showModal(data.item)">
              Assign
            </b-link>
            <b-link
              v-if="data.item.deletedAt && $can('ur_deactivate')"
              @click="restore(data.item.id)"
            >
              Restore
            </b-link>
            <b-link
              v-if="!data.item.deletedAt && $can('ur_deactivate')"
              @click="deactivate(data.item.id)"
            >
              Deactive
            </b-link>
          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BModal,
  BBadge,
  BPagination,
  BOverlay,
} from "bootstrap-vue";
import store from "@/store";
import vSelect from "vue-select";
// eslint-disable-next-line import/no-cycle
import { ref } from "@vue/composition-api";
// eslint-disable-next-line import/no-cycle
import { avatarText } from "@core/utils/filter";
import UsersListFilters from "@/components/userManager/UsersListFilters.vue";
import ActionSelect from "@/components/userManager/ActionSelect.vue";
// eslint-disable-next-line import/no-cycle
import UserListAddNew from "@/components/userManager/UserListAddNew.vue";
import UserAssignRole from "@/components/userManager/UserAssignRole.vue";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// eslint-disable-next-line import/no-cycle
import { mapGetters } from "vuex";
import useUsersList from "./useUsersList";
import * as config from "../../utils/config";
import moment from 'moment'


export default {
  components: {
    BOverlay,
    UserAssignRole,
    UsersListFilters,
    UserListAddNew,
    ActionSelect,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    vSelect,
    BModal,
  },
  setup() {
    // Use toast
    const toast = useToast();
    const isAddNewUserSidebarActive = ref(false);
    const isAssignRoleSidebarActive = ref(false);
    const dataFetchRoles = ref([]);
    const userCheckboxOptions = ref([]);
    const userCheckbox = [];
    const onResetPassConfirm = () => {
      store
        .dispatch("user/resetPass", userCheckboxOptions.value)
        .then((response) => {
          if (response.request.status === 201) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Reset pass successfuly",
                icon: "AlertTriangleIcon",
                variant: "success",
              },
            });
          }
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error action reset password",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };
    const resetAllPass = () => {
      store
        .dispatch("user/resetAllPass")
        .then((response) => {
          if (response.request.status === 201) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Reset all pass successfuly",
                icon: "AlertTriangleIcon",
                variant: "success",
              },
            });
          }
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error action reset password",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };
    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    const PushToArray = (value, ...args) => {
      if (value[0] === true) {
        userCheckboxOptions.value.push(args[0]);
      } else {
        userCheckboxOptions.value = userCheckboxOptions.value.filter(
          (item) => item !== args[0]
        );
      }
    };
    const fetchRoles = () => {
      store
        .dispatch("user/fetchRoles", {
          take: 50,
          page: 1,
        })
        .then((response) => {
          const roles = response.body.data;
          dataFetchRoles.value = roles.map((x) => ({
            label: x.name,
            value: x.id,
          }));
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching roles list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };
    const {
      timeout,
      showTable,
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserAssetConfig,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      regionFilter,
      roleFilter,
      auditedByPWCFilter,
      auditedInMYFilter,
    } = useUsersList();
    setTimeout(async () => {
      fetchRoles();
    }, 0);
    return {
      timeout,
      showTable,
      onResetPassConfirm,
      resetAllPass,
      PushToArray,
      // Sidebar
      dataFetchRoles,
      isAddNewUserSidebarActive,
      isAssignRoleSidebarActive,
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserAssetConfig,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      planOptions,
      statusOptions,
      userCheckboxOptions,
      userCheckbox,
      // Extra Filters
      config,
      regionFilter,
      roleFilter,
      auditedByPWCFilter,
      auditedInMYFilter,
    };
  },
  data() {
    return {
      currentDataUser: {},
      modalShow: false,
      newRole: null,
      nameState: null,
    };
  },
  computed: {
    ...mapGetters("app", ["regionOptions"]),
    ...mapGetters("app", ["answerQuestionOptions"]),
    ...mapGetters("app", ["productConfigTypes"]),
  },
  methods: {
    formatDate(time) {
      return moment(time).format('HH:mm:ss DD/MM/YYYY')
    },
    mapAssetConfig(assetName) {
      return this.productConfigTypes.find((e) => e.value === assetName).label;
    },
    showModal(data) {
      this.currentDataUser = data;
      this.isAssignRoleSidebarActive = true;
    },
    deleteUser(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete this user.", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            store.dispatch("user/deleteUser", { id }).then(() => {
              this.showTable = true;
              clearTimeout(this.timeout);
              this.timeout.value = setTimeout(() => {
                this.refetchData();
                this.showTable = false;
              }, 2000);
            });
          }
        });
    },
    restore(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to restore this user.", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            store.dispatch("user/restoreUser", { id }).then(() => {
              this.showTable = true;
              clearTimeout(this.timeout);
              this.timeout.value = setTimeout(() => {
                this.refetchData();
                this.showTable = false;
              }, 2000);
            });
          }
        });
    },
    deactivate(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to deactivate this user.",
          {
            title: "Please Confirm",
            size: "sm",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            store.dispatch("user/deactiveUser", { id }).then(() => {
              this.showTable = true;
              clearTimeout(this.timeout);
              this.timeout.value = setTimeout(() => {
                this.refetchData();
                this.showTable = false;
              }, 2000);
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.table-b-table-default {
  div {
    margin-top: 1rem;
  }
}
</style>
