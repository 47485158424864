<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetFormCustom"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New User
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- First Name -->
          <validation-provider
            #default="validationContext"
            name="First Name"
            rules="required"
          >
            <b-form-group
              label="First Name"
              label-for="first-name"
            >
              <b-form-input
                id="first-name"
                v-model="userData.firstName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="John"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Last Name -->
          <validation-provider
            #default="validationContext"
            name="Last Name"
            rules="required"
          >
            <b-form-group
              label="Last Name"
              label-for="last-name"
            >
              <b-form-input
                id="last-name"
                v-model="userData.lastName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Doe"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Region -->
          <validation-provider
            #default="validationContext"
            name="Region"
            rules="required"
          >
            <b-form-group
              label="Region"
              label-for="user-region"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.region"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="regionOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-region"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Password -->
          <validation-provider
            #default="validationContext"
            name="Password"
            rules="required"
          >
            <b-form-group
              label="Password"
              label-for="password"
            >
              <b-form-input
                id="password"
                v-model="userData.password"
                type="password"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Title -->
          <validation-provider
            #default="validationContext"
            name="Title"
            rules="required"
          >
            <b-form-group
              label="Title"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="userData.title"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Development"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Job Title -->
          <validation-provider
            #default="validationContext"
            name="Job Title"
            rules="required"
          >
            <b-form-group
              label="Job Title"
              label-for="job-title"
            >
              <b-form-input
                id="job-title"
                v-model="userData.jobTitle"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Team Leader"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Company -->

          <b-form-group
            label="Company"
            label-for="company"
          >
            <b-form-input
              id="company"
              v-model="userData.company"
              autofocus
              trim
              placeholder="Company"
            />

          </b-form-group>
          <!-- User Role -->
          <validation-provider
            #default="validationContext"
            name="User Role"
            rules="required"
          >
            <b-form-group
              label="User Role"
              label-for="user-role"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.roleId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Reason -->
          <validation-provider
            #default="validationContext"
            name="Reason"
            rules="required"
          >
            <b-form-group
              label="Reason"
              label-for="reason"
            >
              <b-form-input
                id="reason"
                v-model="userData.reason"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Reason"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Select date range -->
          <b-form-group
            label="Duration of use"
            label-for="duration"
          >
            <date-range-picker
              ref="picker"
              v-model="dateRange"
              :ranges="false"
              single-date-picker="range"
              opens="opens-right"
              :auto-apply="false"
            >
              <template v-slot:input="picker">
                {{ picker.startDate && picker.endDate
                  ? `${formatDateDDMMYYYY(picker.startDate)} - ${formatDateDDMMYYYY(picker.endDate)}`
                  : 'Picker Date' }}
              </template>
            </date-range-picker>
          </b-form-group>
          <!-- Asset type config -->
          <validation-provider
            #default="validationContext"
            name="Asset"
            rules="required"
          >
            <b-form-group
              label="Asset type config"
              label-for="user-asset"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.productConfig"
                multiple
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="productType"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-asset"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- AuditedInMY -->
          <validation-provider
            #default="validationContext"
            name="auditedInMY"
            rules="required"
          >
            <b-form-group
              label="Is your company audited by PwC locally?"
              label-for="auditedInMY"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.auditedInMY"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="answerQuestionOptions"
                :clearable="false"
                input-id="auditedInMY"
                @input="showAuditedByPWC"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- AuditedByPWC -->
          <validation-provider
            v-if="isShowAuditedByPWC"
            #default="validationContext"
            name="auditedByPWC"
            rules="required"
          >
            <b-form-group
              label="Is your global ultimate parent audited by PwC?"
              label-for="auditedByPWC"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.auditedByPWC"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="answerQuestionOptions"
                :clearable="false"
                input-id="auditedByPWC"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>

import DateRangePicker from 'vue2-daterange-picker'
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { mapGetters } from 'vuex'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    DateRangePicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    regionOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  computed: {
    ...mapGetters('product', ['productType']),
    ...mapGetters('app', ['answerQuestionOptions']),
  },
  setup(props, { emit }) {
    const dateRange = ref({ startDate: null, endDate: null })
    const isShowAuditedByPWC = false
    const toast = useToast()
    const blankUserData = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      title: '',
      jobTitle: '',
      roleId: 0,
      region: '',
      reason: '',
      auditedInMY: '',
      auditedByPWC: '',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      if (dateRange.value) {
        userData.value.startDate = dateRange.value.startDate
        userData.value.endDate = dateRange.value.endDate
      }
      if (!userData.value.auditedByPWC) {
        userData.value.auditedByPWC = {
          value: 'dontknow',
        }
      }
      const transFerData = {
        email: userData.value.email,
        password: userData.value.password,
        region: userData.value.region,
        title: userData.value.title,
        firstName: userData.value.firstName,
        lastName: userData.value.lastName,
        jobTitle: userData.value.jobTitle,
        roleId: userData.value.roleId,
        auditedInMY: userData.value.auditedInMY.value,
        auditedByPWC: userData.value.auditedByPWC.value,
        reason: userData.value.reason,
        startDate: userData.value.startDate,
        endDate: userData.value.endDate,
        productConfig: userData.value.productConfig,
        company: userData.value.company,
      }

      store.dispatch('user/addUser', transFerData)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        }).catch(e => {
          toast({
            component: ToastificationContent,
            props: {
              title: e.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)
    return {
      isShowAuditedByPWC,
      toast,
      userData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      dateRange,
    }
  },
  methods: {
    showAuditedByPWC() {
      if (this.userData.auditedInMY.value === 'no') {
        this.isShowAuditedByPWC = true
      } else {
        this.isShowAuditedByPWC = false
      }
    },
    resetFormCustom() {
      this.resetForm()
      this.dateRange.startDate = null
      this.dateRange.endDate = null
      this.isShowAuditedByPWC = false
    },
    formatDate(time) {
      return moment(time).format('HH:mm:ss DD/MM/YYYY')
    },
    formatDateDDMMYYYY(time) {
      return moment(time).format('DD/MM/YYYY')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
